import React from "react";
import { Heading, Text, Flex } from "@chakra-ui/react";
import PropTypes from "prop-types";

function AttributesBox({ title, description }) {
  return (
    <Flex
      flexDirection="column"
      borderRadius="lg"
      boxShadow="lg"
      p="6"
      border="1px solid #eaeaea"
      transition="all 0.2s"
      _hover={{
        boxShadow: "lg",
        transform: "scale(1.01) translateY(-1%)",
      }}
      textAlign="center"
      alignSelf="stretch"
      height="100%"
      bg="gray.200"
    >
      <Heading as="h4" size="lg" fontWeight="medium" mb="6">
        {title}
      </Heading>

      <Text mb="6">{description}</Text>
    </Flex>
  );
}

export default AttributesBox;

AttributesBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
